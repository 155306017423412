<template>
  <section id="dashboard-ecommerce">
    <div>
      <!-- Filters -->
      <dashboard-baraem-filters

        :employe-filter.sync="employeFilter"
        :goal-filter.sync="goalFilter"
        :date-filter.sync="date"
        :duration-filter.sync="durationType"
        :role-filter.sync="roleFilter"
        :employe-options="employeOptions"
        :goal-options="goalOptions"
        :role-options="roleOptions"
        :duration-options="durationOptions"
      />

      <!-- Table Container Card -->

      <b-card
        no-body
        class="mb-0"
      >
   
        <b-card-body>
          <!-- apex chart -->
          <vue-apex-charts
            height="400"

            :options="chartOptions"
            :series="chartOptions.series"
          />

          <!-- chart info -->
        </b-card-body>
      </b-card>
      <hr>
    
      <b-card
        no-body
        class="mb-0"
      >
      <div class="text-center mt-5" style="color: #b4b7bd !important;">

        <h3>
        Case Mangaer Stats
      </h3>
      </div>
     
       
        <b-card-body>
          <b-row>

            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>تاريخ البداية </label>
                  <flat-pickr
                    v-model="start_date"
                    class="form-control"
                    placeholder="حدد تاريخ "
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>تاريخ النهاية </label>
                  <flat-pickr
                    v-model="end_date"
                    class="form-control"
                    placeholder="حدد تاريخ "
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >

              <label>Case Manger</label>
              <v-select

                v-model="caseMangerId"
                :options="CaseManagerOption"
                :reduce="option => option.value"
                :clearable="true"
              />

            </b-col>
          </b-row>
          <!-- apex chart -->
          <vue-apex-charts
            height="400"

            :options="chartOptionsCaseMangaer"
            :series="chartOptionsCaseMangaer.series"
          />

          <!-- chart info -->
        </b-card-body>
      </b-card>
      <hr>

      <b-card
        no-body
        class="mb-0"
        title="Coordinator"
      >
        
        <div class="text-center mt-5" style="color: #b4b7bd !important;">

<h3>
  Coordinator
</h3>
</div>
        <b-card-body>
          <b-row v-for="item in personsPerSubService">
            <b-col
              xl="4"
              md="4"
              sm="6"
            >
              {{ item.service_name }}
            </b-col>
            <b-col
              xl="4"
              md="4"
              sm="6"
            >
              {{ item.persons_count }}
            </b-col>
          </b-row>
          <!-- apex chart -->
          <vue-apex-charts
            height="400"

            :options="chartOptionsCoordinator"
            :series="chartOptionsCoordinator.series"
          />

          <!-- chart info -->
        </b-card-body>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInvalidFeedback,
 
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import VueApexCharts from 'vue-apexcharts'
import { ref, onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'

import dashboardBaraemFilters from './dashboardBaraemFilters.vue'
import usedashboardBaraemList from './usedashboardBaraemList'
import 'flatpickr/dist/flatpickr.css'
import DashboardStoreModule from './DashboardStoreModule'

export default {
  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) { store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, DashboardStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) { store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME) }
    })

    const goalOptions = [
      { value: 0, label: 'قسم التسجيل ' },
      { value: 1, label: ' مدراء الحالة' },
      { value: 2, label: ' الفريق الجوال' },
    ]

    const durationOptions = [
      { value: 0, label: ' يومي' },
      { value: 1, label: '  شهري' },
      { value: 2, label: '  سنوي' },
    ]
    const {
      roleOptions,
      fetchBenServices,
      employeOptions,
      tableColumns,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // durationType,
      // date,

      // goalFilter,
      // employeFilter,
      // roleFilter,
      openStatus,
      changeStatus,
      // chartOptions,

      succesStory,
      series,

    } = usedashboardBaraemList()

    return {
      // Sidebar
      series,
      durationOptions,
      employeOptions,
      // chartOptions,
      fetchBenServices,
      changeStatus,

      openStatus,
      succesStory,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // Filter
      // durationType,
      // date,
      // goalFilter,
      // employeFilter,
      // roleFilter,

      goalOptions,
      roleOptions,

    }
  },
  components: {
    VueApexCharts,
    dashboardBaraemFilters,
    bCardTitle,
    BButton,

    BDropdownItemButton,
    BFormInvalidFeedback,

    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      label: [],
      CaseManagerOption: [],
      goalFilter: '',
      date: '',
      durationType: '',
      roleFilter: '',
      employeFilter: '',
      chartOptionsCoordinator: {
        series: [
        ],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },

        noData: {
          text: 'No Data...',
        },

        labels: ['قيد الانتظار', 'مفتوحة', 'مغلقة', 'بانتظار الموافقة', 'حالة جديدة من قسم التسجيل', 'تم تحويلها'],

      },
      chartOptionsCaseMangaer: {
        series: [
          {
            label: 'Case Manager ',
            data: [],
          }],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },

        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No Data...',
        },
        labels: ['قيد الانتظار', 'مفتوحة', 'مغلقة', 'بانتظار الموافقة', 'حالة جديدة من قسم التسجيل', 'تم تحويلها'],

      },
      chartOptions: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#ffe700', '#FFA1A1'],

        labels: ['  الهدف  ', '  الانجاز  '],

        plotOptions: {
          pie: {

            donut: {
              labels: {
                show: true,

                total: {
                  show: true,
                  offsetY: 15,

                },
              },
            },
          },
        },

      },
      caseMangerId: '',
      personsPerSubService: [],
      end_date: '2018-09-05',
      start_date: '2023-11-05',
    }
  },
  watch: {
    goalFilter() {
      this.getData()
    },
    date() {
      this.getData()
    },
    durationType() {
      this.getData()
    },
    employeFilter() {
      this.getData()
    },
    roleFilter() {
      this.getData()
    },
    start_date() {
      this.getCaseMangerStats()
    },
    end_date() {
      this.getCaseMangerStats()
    },
    caseMangerId() {
      this.getCaseMangerStats()
    },

  },
  created() {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()

    today = `${yyyy}-${mm}-${dd}`
    this.start_date = today

    this.date = today
    this.getCaseMangerStats()
    this.getCoordinator()
    this.getCaseMangerOption()
  },
  methods: {
    getCaseMangerOption() {
      const url = '/api/v1/information_manager/case-managers'
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
        console.log("CaseManagerOption",res.data.data)
        res.data.data.forEach((el) => {
          this.CaseManagerOption.push({ value: el.id, label: `${el.first_name} ${el.last_name} `

      })
    })})
    },
    getCaseMangerStats() {
      const url = `/api/v1/stats/case-manager?start_date=${this.start_date}&end_date=${this.end_date}&user_id=${this.caseMangerId}`
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
        console.log(res.data.data)
        
        // this.chartOptionsCaseMangaer.xaxis.categories = res.data.data[0].stats.labels
        this.chartOptionsCaseMangaer.series = res.data.data[0].stats.values
      })
    },
    getCoordinator() {
      const url = '/api/v1/stats/get-stats-by-main-service'
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
        console.log(res.data)
        this.personsPerSubService = res.data.personsPerSubService
        const data = res.data.personsPerMainService[0].stats
        this.chartOptionsCoordinator.series = data.values
        // data.labels.forEach(el=>{
        //   this.chartOptionsCoordinator.xaxis.categories.push(el)
        // })

        window.dispatchEvent(new Event('resize'))
      })
    },

    getData() {
      window.dispatchEvent(new Event('resize'))
      this.$http.get(`/api/v1/achievement_target_stats?goal_type=${this.goalFilter}&date=${this.date}&is_summary=true&employee_id=${this.employeFilter}&duration_type=${this.durationType}
      `)
        .then(response => {
          window.dispatchEvent(new Event('resize'))
          // console.log("stat",response)
          // console.log("stat",response.data.total_goal)
          const data = []

          if (response.data) {
            data.push(parseInt(response.data.inserted_families_stats.total_goal))
            data.push(parseInt(response.data.inserted_families_stats.total_inserted_families))
            this.chartOptions.series = data
            window.dispatchEvent(new Event('resize'))
          }
        })
    },
  },
}

</script>

<style lang="scss">
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
